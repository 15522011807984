<script setup lang="ts">
const props = defineProps<{
  modelValue: boolean;
}>();
const emit = defineEmits(['update:modelValue']);

const isOpen = computed({
  get: () => props.modelValue,
  set: (value) => {
    emit('update:modelValue', value);
  },
});
</script>

<template>
  <UModal
    v-model="isOpen"
    :ui="{
      base: '!max-w-[700px]',
    }"
  >
    <div class="flex flex-col items-center justify-center gap-7 p-4 md:p-10">
      <WalletHowCyclesWork />

      <UButton
        size="md"
        color="secondary"
        icon="i-heroicons-x-mark"
        @click="isOpen = false"
      >
        Close
      </UButton>
    </div>
  </UModal>
</template>
